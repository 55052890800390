import { orchestrator } from 'satcheljs';
import { getPromoViaVoucherCodeAction } from '../action/get-promo-via-voucher-code.action';
import { getPromoViaVoucherCodeApi } from '../api/promotion/get-promo-via-voucher-code.api';

orchestrator(getPromoViaVoucherCodeAction, async (actionMessage) => {
  const { voucherCode, callback } = actionMessage;

  try {
    if (!voucherCode) throw 'error.emptyCouponCode';

    const response = await getPromoViaVoucherCodeApi(voucherCode);

    callback?.success?.(response);
  } catch (error) {
    callback?.error?.(String(error));
  } finally {
    callback?.finally?.();
  }
});
