import { TActionCallback } from '@totopkg/shared-util-common';
import { action } from 'satcheljs';
import { IPromotion } from '../type/promotion.type';

export const getPromoViaVoucherCodeAction = action(
  'getPromoViaVoucherCodeAction',
  (voucherCode: string | undefined, options?: { callback?: TActionCallback<IPromotion> }) => {
    return {
      voucherCode,
      ...options,
    };
  }
);
